<template>
  <div class="role">
    <div v-if="$route.path == '/share/shareIndex'">
      <div class="roleHeader">
        <div style="width: 100%">
          <span>下单时间</span>
          <el-date-picker
              ref="RefPicker"
              v-model="time"
              :clearable="false"
              :picker-options="pickerOptions"
              end-placeholder="结束日期"
              range-separator="~"
              size="small"
              start-placeholder="开始日期"
              style="width: 226px; margin-right: 24px"
              type="daterange"
              value-format="yyyy-MM-dd"
              @change="changeDataPicker"
          >
          </el-date-picker>
          <span>状态</span>
          <el-select
              v-model="statusVal"
              placeholder="请选择"
              size="small"
              style="width: 160px; margin-right: 24px"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option label="已开方" value="2"></el-option>
            <el-option label="待开方" value="1"></el-option>
          </el-select>
          <span>搜索</span>
          <el-input
              v-model="nameVal"
              placeholder="输入角色进行搜索"
              size="small"
              style="width: 200px; margin-right: 24px"
          ></el-input>
          <el-button size="small" type="primary" @click="searchClick"
          >查询
          </el-button
          >
          <el-button size="small" @click="resetClick">重置</el-button>
        </div>
      </div>
      <div class="roleTable">
        <el-table
            :data="tableData"
            height="calc(100vh - 256px)"
            stripe
            style="width: 100%"
        >
          <el-table-column label="序号" type="index" width="50">
          </el-table-column>
          <el-table-column label="订单编号" prop="order_sn"></el-table-column>
          <el-table-column label="客户名称" prop="buyer_name"></el-table-column>
          <el-table-column label="下单日期" prop="created_at"></el-table-column>
          <el-table-column label="处方号" prop="recipe_sn"></el-table-column>
          <el-table-column label="开方日期" prop="over_at"></el-table-column>
          <el-table-column label="开方医师" prop="doctor_name"></el-table-column>
          <el-table-column label="开方科室" prop="dept_name"></el-table-column>
          <el-table-column label="审方医师" prop="audit_doctor"></el-table-column>
          <el-table-column label="审方药师" prop="audit_pharm"></el-table-column>
          <el-table-column label="状态" prop="status_text">
            <template slot-scope="scope">
              <div class="stateTag">
                {{ scope.row.status_text }}
                <sup
                    :class="{ is_do: scope.row.status === 1 }"
                    class="badge_content"
                ></sup>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <!--            待开方-->
              <div v-if="scope.row.status === 1">
                <el-button type="text" @click="submitRecipe(scope.row)">
                  开方
                </el-button>
              </div>
              <!--            已开方-->
              <div v-else>
                <el-button type="text" @click="shareDetailClick(scope.row)">
                  查看
                </el-button>
                <!--                <el-divider direction="vertical"></el-divider>-->
                <!--                <el-button type="text" @click="editClick(scope.row)">-->
                <!--                  打印处方单-->
                <!--                </el-button>-->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="padding-top: 8px; box-sizing: border-box; text-align: right">
        <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 30, 50]"
            :size="450"
            :total="total"
            background
            layout=" prev, pager, next, jumper, sizes,total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>

    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import {
  operationGetTree,
} from "../../api/role/role";
import {getRecipeList, submitRecipe} from "@/api/share/share";

let formData = {
  roleVal: "",
  htqx: [],
};
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      time: [],
      tableData: [],

      nameVal: "",
      statusVal: "0",

      currentPage: 1,
      pageSize: 10,
      total: 0,

      drawer: false,
      numberValidateForm: JSON.parse(JSON.stringify(formData)),

      props: {
        label: "name",
        value: "id",
        children: "children",
      },
      treeData: [],

      editStatus: null,

      start_time: "",
      end_time: ""
    };
  },
  created() {
    // 初始化下单时间为最近一周
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    this.start_time = start
    this.end_time = end
    this.time = [this.end_time, this.start_time]

    // this.roleGetRoleListAxios();
    // this.operationGetTreeAxios();
    this.getRecipeList()

  },
  methods: {
    // 开方
    submitRecipe(e) {
      this.$confirm('是否确认开方?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code} = await submitRecipe({
          recipe_id: e.id
        })
        if (code !== 200) return
        this.$message({
          type: 'success',
          message: '开方成功!'
        });
        await this.getRecipeList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消开方!'
        });
      });


    },

    // 时间切换
    changeDataPicker(e) {
      if (e.length === 2) {
        this.start_time = e[0]
        this.end_time = e[1]
      }
    },
    // 获取处方列表
    async getRecipeList() {
      const {code, data} = await getRecipeList({
        start_time: this.start_time,
        end_time: this.end_time,
        status: this.statusVal,
        name: this.nameVal,
        page: this.currentPage,
        limit: this.pageSize,
      })
      if (code !== 200) return
      this.tableData = data.data;
      this.total = data.total;
    },
    // 前往-查看处方单
    shareDetailClick(row) {
      this.$router.push({
        path: "/share/shareIndex/shareDetail",
        query: {
          id: row.id
        },
      });
    },
    // 前往-打印处方单
    editClick(row) {
      this.drawer = true;
      this.$nextTick(() => {
        this.numberValidateForm.roleVal = row.name;
        this.numberValidateForm.htqx = row.permission.map(String);
        this.$refs.tree.setCheckedKeys(row.permission);
        this.editStatus = row;
        console.log(row.permission);
      });
    },
    operationGetTreeAxios() {
      operationGetTree().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.treeData = res.data;
        }
      });
    },
    searchClick() {
      this.getRecipeList();
    },
    resetClick() {

      this.nameVal = "";
      this.statusVal = "";
      this.currentPage = 1;
      this.pageSize = 10;
      this.getRecipeList();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.currentPage = 1;
      this.pageSize = val;
      this.getRecipeList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getRecipeList();
    },
  },
};
</script>
<style lang="scss" scoped>
.roleHeader {
  padding: 24px 16px 16px 16px;
  box-sizing: border-box;
  display: flex;

  span {
    font-size: 14px;
    color: #394359;
    margin-right: 8px;
  }
}

.color1 {
  color: #029b68;
}

.color2 {
  color: #de241b;
}

.drawerContent {
  position: relative;
  height: calc(100vh - 52px);
}

.drawerContent_box {
  padding: 24px 16px 0px 16px;
  box-sizing: border-box;
  text-align: left;
  border-bottom: 1px solid #c9cada;
}

.drawerContent_tree {
  padding: 0 16px 0px 16px;
  box-sizing: border-box;
}

.drawerContent_footer {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #fff;
  height: 50px;
  line-height: 50px;
}

.drawerContent_sroll {
  height: calc(100vh - 102px);
  overflow: auto;
}
</style>
